import { instance } from '@/services/klarbuss-api'
import { RampName } from '@/types/ramp-name'
import { Arrival } from '@/types/arrival'
import axios, { CancelTokenSource } from 'axios'

const CancelToken = axios.CancelToken

let cancelToken: CancelTokenSource = CancelToken.source()

interface GetWashListParams {
  trafficDate: string
  ramps?: string[]
}

export const getWashList = async (
  date: string,
  ramps?: RampName[]
): Promise<Arrival[]> => {
  cancelToken.cancel()
  cancelToken = CancelToken.source()
  if (!cancelToken) return Promise.resolve([])

  const params: GetWashListParams = { trafficDate: date }

  if (ramps) {
    params.ramps = ramps
  }

  try {
    const { data } = await instance.get('/wash-list', {
      cancelToken: cancelToken.token,
      params,
    })
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}
